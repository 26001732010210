.navbar-logo img {
  height: 80px;
  /* Adjust the logo size as needed */
}

.navbar-links {
  display: flex;
  gap: 2rem;
}

.navbar-link {
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: 600;
  text-decoration: none;
}

.navbar-link:hover {
  color: #7800ff;
}

.navbar-signin {
  background-color: #7800ff;
  color: #ffffff;
  border: none;
  font-weight: bold;
}

.navbar-signin:hover {
  background-color: #5a00cc;
  color: #ffffff;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  color: #ffffff;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  background: transparent;
  height: 112px;
  box-sizing: border-box;
  transition: 0.3s linear;
}

.navbar.active {
  background: #1f2129d5;
}

.navbar .menu-container-style {
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-right: 50px;
}

.navbar .nav-menu {
  list-style-type: none;
  display: flex;
  gap: 30px;
  padding-left: 0;
  margin-right: 30px;
}


.navbar .nav-menu a {
  color: #ffffff;
  font-family: Poppins;
  font-size: 25px;
  font-weight: 900;
  line-height: 41px;
  letter-spacing: -0.005em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  text-decoration: none;
}


@media screen and (max-width: 769px) {
  .navbar .menu-container-style {
    margin-right: 0;
  }

}