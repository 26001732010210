.quantity-container {
  display: flex;
  align-items: center;
  justify-content: space-between; /* This will right-align the buttons */
  width: 80%;
}

.quantity-label {
  margin-right: auto;
  padding-right: 10px;
  /* Ensure alignment with other labels */
}

.quantity-controls {
  display: flex;
  align-items: center;
  gap: 8px; /* Adds space between the buttons and input */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
