.dmn-logo-container {
  width: 100%;
  max-width: 100px; /* Smaller maximum width */
  margin: 0 auto; /* Center the container if needed */
}

.dmn-logo {
  width: 100%;
  height: auto;
}
