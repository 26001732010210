.ant-select-selector {
  background-color: #1f2129 !important;
  text-decoration: white !important;
  border: none !important;
  position: static !important;
}

.pieceType .ant-select-selection-overflow {
  color: aliceblue;
}
.pieceType .ant-select-selection-placeholder {
  color: grey !important;
}

.pieceType .ant-select-selection-item {
  background-color: #7800ff !important;
}

.pieceType .ant-select-selection-item-remove {
  color: white !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #7800ff !important;
  border-color: #7800ff !important;
}

.ant-input-number input {
  color: white !important;
}

.pieceUnit .ant-select-selection-placeholder {
  color: grey !important;
}

.pieceUnit .ant-select-arrow {
  color: grey !important;
}

.ant-select-selection-item {
  color: white !important;
}
