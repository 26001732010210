/* .landing-page {
  background-color: #1f2128;
  width: 100%;
}

.landing-page .overlap-wrapper {
  background-color: #1f2128;
  height: 1130px;
  width: 1680px;
}

.landing-page .overlap {
  height: 1130px;
  left: 33px;
  position: relative;
  width: 1647px;
}

.landing-page .overlap-group {
  height: 1130px;
  left: 45px;
  position: absolute;
  top: 0;
  width: 1602px;
}

.landing-page .DMNICON {
  height: 1061px;
  left: 679px;
  object-fit: cover;
  position: absolute;
  top: -83px;
  width: 1033px;
  opacity: 0.3;
} */

/* .landing-page .comment-button {
  display: flex !important;
  height: 49px !important;
  left: 856px !important;
  position: absolute !important;
  top: 75px !important;
  width: 140px !important;
}

.landing-page .div {
  color: #7800ff;
  font-family: "Poppins-Black", Helvetica;
  font-size: 40px;
  font-weight: 900;
  left: 9px;
  letter-spacing: -0.2px;
  line-height: 55.8px;
  position: absolute;
  text-shadow: 0px 4px 4px 8px #000000;
  top: 508px;
  width: 962px;
}

.landing-page .p {
  color: #ffffff;
  font-family: "Poppins-Black", Helvetica;
  font-size: 40px;
  font-weight: 900;
  left: 9px;
  letter-spacing: -0.2px;
  line-height: 55.8px;
  position: absolute;
  text-shadow: 0px 4px 4px #00000040;
  top: 664px;
  width: 925px;
}

.landing-page .text-wrapper-2 {
  color: #ffffff;
  font-family: "Poppins-Black", Helvetica;
  font-size: 125px;
  font-weight: 900;
  left: 0;
  letter-spacing: -0.62px;
  line-height: 174.4px;
  position: absolute;
  text-shadow: 7px 5px 4px 8px #000000;
  top: 363px;
  white-space: nowrap;
  width: 525px;
}

.landing-page .text-wrapper-3 {
  color: #ffffff;
  font-family: "Poppins-Black", Helvetica;
  font-size: 30px;
  font-weight: 900;
  left: 621px;
  letter-spacing: -0.15px;
  line-height: 41.9px;
  position: absolute;
  top: 76px;
  white-space: nowrap;
  width: 195px;
}

.landing-page .text-wrapper-4 {
  color: #ffffff;
  font-family: "Poppins-Black", Helvetica;
  font-size: 30px;
  font-weight: 900;
  left: 873px;
  letter-spacing: -0.15px;
  line-height: 41.9px;
  position: absolute;
  top: 77px;
  white-space: nowrap;
  height: "fit-content";
}

.landing-page .generate-instance {
  box-shadow: 0px 4px 4px #00000040 !important;
  display: flex !important;
  height: 59px !important;
  left: 11px !important;
  position: absolute !important;
  top: 826px !important;
  width: 308px !important;
}

.landing-page .text-wrapper-5 {
  color: #ffffff;
  font-family: "Poppins-Black", Helvetica;
  font-size: 30px;
  font-weight: 900;
  left: 44px;
  letter-spacing: -0.15px;
  line-height: 41.9px;
  position: absolute;
  text-shadow: 0px 4px 4px #00000040;
  top: 834px;
  white-space: nowrap;
  width: 256px;
}

.landing-page .img {
  height: 96px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 40px;
  width: 93px;
}

.landing-page .text-wrapper-6 {
  color: #ffffff;
  font-family: "Poppins-Black", Helvetica;
  font-size: 30px;
  font-weight: 900;
  left: 141px;
  letter-spacing: -0.15px;
  line-height: 41.9px;
  position: absolute;
  top: 76px;
  white-space: nowrap;
  width: 138px;
}

.landing-page .text-wrapper-7 {
  color: #ffffff;
  font-family: "Poppins-Black", Helvetica;
  font-size: 30px;
  font-weight: 900;
  left: 310px;
  letter-spacing: -0.15px;
  line-height: 41.9px;
  position: absolute;
  top: 76px;
  white-space: nowrap;
  width: 160px;
}

.landing-page .text-wrapper-8 {
  color: #ffffff;
  font-family: "Poppins-Black", Helvetica;
  font-size: 30px;
  font-weight: 900;
  left: 505px;
  letter-spacing: -0.15px;
  line-height: 41.9px;
  position: absolute;
  top: 76px;
  white-space: nowrap;
  width: 160px;
} */

.page-content-container {
  margin-top: 112px;
}

.hero-section {
  font-family: Poppins;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  display: flex;
  align-items: center;
  height: calc(100vh - 112px);
  min-height: 600px;
  position: relative;
}

.hero-section::before {
  content: '';
  width: 50vw;
  display: block;
  position: absolute;
  right: 0;
  top: -112px;
  height: 100vh;
  background: url('../../assets/DMNICON_transparent.png');
  background-position: left center;
  background-repeat: no-repeat;
  background-size: cover;
}

.hero-section .content-container {
  width: 60%;
  padding-left: 75px;
  padding-bottom: 130px;
}

.hero-section .content-title {
  font-size: 125px;
  font-weight: 900;
  line-height: 150px;
  letter-spacing: -0.005em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #FFFFFF;
  margin: 0;
  text-shadow: 7px 5px 4px #000000;
}

.hero-section .content-subtitle {
  font-size: 40px;
  font-weight: 900;
  line-height: 55px;
  letter-spacing: -0.005em;
  color: #3198FF;
  margin: 0;
  text-shadow: 0px 4px 4px #000000;
}

.hero-section .content-details {
  color: #FFFFFF;
  text-shadow: 0px 4px 4px #00000040;
  font-size: 40px;
  font-weight: 900;
  line-height: 41.86px;
  letter-spacing: -0.005em;
  /* margin-top: 80px; */
}

.hero-section .content-button {
  border-radius: 10px;
  background: #3198FF;
  border: none;
  cursor: pointer;
  font-family: Poppins;
  font-size: 30px;
  font-weight: 900;
  line-height: 40px;
  letter-spacing: -0.005em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #FFFFFF;
  box-shadow: 0px 4px 4px 0px #00000040;
  height: 50px;
  width: 280px;
}



/* product and feature page design */
.product-feature-section {
  font-family: Poppins;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  display: flex;
  flex-direction: column;
  padding-inline: 120px;
  padding-block: 50px;
}

.product-feature-section .product-feature-title {
  font-size: 48px;
  font-weight: 900;
  line-height: 66.97px;
  letter-spacing: -0.005em;
  color: #FFFFFF;
  text-align: center;
  text-shadow: 7px 5px 4px #000000;
}

.product-feature-section .product-feature-description {
  font-weight: 900;
  line-height: 55px;
  letter-spacing: -0.005em;
  text-align: center;
  color: #3198FF;
  text-shadow: 0px 4px 8px #000000;

  font-size: 40px;
  margin-inline: auto;
  margin-top: 40px;
}

.product-feature-section .product-feature-wrapper {
  margin-top: 40px;
}

.product-feature-section .product-feature-wrapper .product-feature {
  margin-top: 40px;
}

.product-feature-section .features-card-container {
  display: flex;
  margin-top: 40px;
}

.product-feature-section .features-card-container .feature-card-wrapper .feature-card {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.product-feature-section .features-card-container .feature-card .feature-title {
  font-size: 40px;
  font-weight: 900;
  line-height: 55px;
  letter-spacing: -0.005em;
  color: #FFFFFF;
}

.product-feature-section .feature-card .feature-body {
  display: flex;
  gap: 50px;
}

.product-feature-section .feature-card .text-container {
  display: flex;
  flex-direction: column;
}

.product-feature-section .feature-card .text-container .feature-description {
  font-size: 24px;
  font-weight: 900;
  line-height: 33px;
  letter-spacing: -0.005em;
  text-align: center;
  color: #3198FF;
}

.product-feature-section .feature-card .text-container .feature-button {
  background: #3198FF;
  font-size: 28px;
  font-weight: 900;
  line-height: 41.86px;
  letter-spacing: -0.005em;
  text-align: center;
  border-radius: 10px;
  color: #FFFFFF;

  width: 280px;
  height: 50px;
  margin-inline: auto;
  margin-top: 20px;
}



/* artLink concierge page design */
.artLink-concierge-section {
  font-family: Poppins;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding-inline: 120px;
  padding-block: 100px;
}

.artLink-concierge-section .artLink-concierge-logo {
  height: 114px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin: auto; */
  margin-bottom: 34px;
}

.artLink-concierge-section .artLink-concierge-logo .concierge-img {
  height: 100%;
  width: 100%;
}

.artLink-concierge-section .artLink-concierge-title {
  font-size: 48px;
  font-weight: 900;
  line-height: 66.97px;
  letter-spacing: -0.005em;
  color: #FFFFFF;
  text-align: center;
  text-shadow: 7px 5px 4px #000000;
}

.artLink-concierge-section .artLink-concierge-description {
  font-weight: 900;
  line-height: 55px;
  letter-spacing: -0.005em;
  text-align: center;
  color: #3198FF;
  text-shadow: 0px 4px 2px #000000;

  font-size: 40px;
  margin-inline: auto;
  margin-top: 50px;
}

.artLink-concierge-section .concierge-button {
  background: #3198FF;
  font-size: 28px;
  font-weight: 900;
  line-height: 41.86px;
  letter-spacing: -0.005em;
  text-align: center;
  border-radius: 10px;
  color: #FFFFFF;

  width: 280px;
  height: 50px;
  margin-top: 70px;
}


@media screen and (max-width: 1600px) {
  .hero-section .content-title {
    font-size: 100px;
    line-height: 130px;
  }

  .hero-section .content-subtitle {
    font-size: 30px;
    line-height: 44px;
  }

  .hero-section .content-details {
    font-size: 30px;
    line-height: 44px;
  }
}

@media screen and (max-width: 1400px) {
  .product-feature-section .features-card-container .feature-card-wrapper {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }

  .product-feature-section .feature-card .feature-body {
    gap: 30px;
  }
}



@media screen and (max-width: 699px) {
  .hero-section {
    height: auto;
    align-items: start;
    height: calc(100vh - 210px);
    max-height: 800px;
  }

  .hero-section::before {
    width: 100vw;
    z-index: 0;
    max-height: 800px;
  }

  .hero-section .content-container {
    margin-top: 20px;
    width: 100%;
    padding-left: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    padding-inline: 15px;
  }

  .hero-section .content-title {
    font-size: 80px;
    text-align: center;
    line-height: 130px;
  }

  .hero-section .content-subtitle {
    font-size: 24px;
    line-height: 40px;
    text-align: center;
  }

  .hero-section .content-details {
    font-size: 24px;
    line-height: 40px;
    text-align: center;
    margin-top: 60px;
  }

  .product-feature-section {
    padding-inline: 40px;
    padding-block: 70px;
  }

  .product-feature-section .feature-card .feature-body {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .product-feature-section .features-card-container {
    row-gap: 50px;
    width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .product-feature-section .features-card-container .feature-card-wrapper {
    padding-left: 0 !important;
    padding-right: 0 !important;
    box-sizing: border-box;
  }

  .product-feature-section .features-card-container .feature-card .feature-title {
    font-size: 32px;
    line-height: 40px;
    text-align: center;
  }

  .product-feature-section .product-feature-title {
    font-size: 40px;
    line-height: 50px;
  }

  .product-feature-section .product-feature-description {
    font-weight: 900;
    line-height: 38px;
    font-size: 20px;
    margin-top: 20px;
  }

  .artLink-concierge-section {
    padding-inline: 40px;
    padding-block: 70px;
  }

  .artLink-concierge-section .artLink-concierge-title {
    font-size: 40px;
    line-height: 50px;
  }

  .artLink-concierge-section .artLink-concierge-description {
    font-weight: 900;
    line-height: 38px;
    font-size: 20px;
    margin-top: 20px;
  }
}