.no-outline:focus {
  outline: none;
}

.ant-popover {
  animation: bounce 1s linear infinite;
}

@keyframes bounce {
  from,
  to {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }
}
