.responsive-image-container,
.responsive-image-container-sold {
  width: 100%;
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
  margin-bottom: 50px;
}

/* Additional styles for sold container */
.responsive-image-container-sold {
  margin-top: 20px;
}

/* Media query for larger screens */
@media (min-width: 768px) {
  .responsive-image-container,
  .responsive-image-container-sold {
    padding-bottom: 22.25%; /* Adjust padding-bottom for desktop */
  }
}

.responsive-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain; /* or use 'cover' if you want the image to fill the container without distortion */
}

.toggle-icon {
  position: absolute;
  top: 0px;
  right: -2px;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  color: white;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1; /* Ensure it's on top of the image */
}

.toggle-icon-sold {
  position: absolute;
  top: 10px;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  color: white;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1; /* Ensure it's on top of the image */
}
